<template>
  <div class="content-wrapper view-drugsitemlist-manage pt-3">
    <div class="content">
      <div class="card">
        <div class="card-body">
          <h4><i class="fas fa-list"></i> ลิสต์ยา และสินค้า</h4>
          <div class="row mx-1 my-3">
            <b-form-input
              type="search"
              class="col required"
              autofocus
              v-model="filter"
              placeholder="ค้นหารายการยา และสินค้า จากชื่อ หรือประเภท"
            ></b-form-input>
            <b-button
              v-if="isCounterOrManager"
              class="col-5 col-sm-4 col-md-3 ml-3"
              variant="primary"
              @click="openDrugsItemModal()"
            >
              <i class="fas fa-plus"></i> เพิ่มรายการ
            </b-button>
          </div>
          <b-table
            id="drugsItemListManageTable"
            ref="drugsItemListManageTable"
            hover
            small
            sort-icon-left
            head-variant="light"
            :items="itemList"
            :fields="fields"
            :filter="filter"
            responsive
          >
            <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
            <!-- <template
              v-slot:cell(creationDt)="row"
            >{{ moment(row.item.creationDt).locale("th").add(543, "year").format('DD MMM YY') }}</template> -->
            <template v-slot:cell(buttons)="row">
              <b-button-group class="show-when-hovered" size="xs">
                <b-button
                  variant="outline-info"
                  :pressed="row.item.favorite"
                  @click="favToggle(row.item)"
                >
                  <i class="far fa-star"></i>
                </b-button>
                <b-button
                  v-if="isCounterOrManager"
                  variant="outline-secondary"
                  @click="openDrugsItemModal(row.item)"
                >
                  <i class="fas fa-edit"></i>
                </b-button>
                <b-button
                  v-if="isCounterOrManager"
                  variant="outline-danger"
                  @click="deleteDrugsItem(row.item)"
                >
                  <i class="fas fa-trash-alt"></i>
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>
      </div>
      <DrugsItem ref="DrugsItemModal"></DrugsItem>
      <Dialog ref="Dialog"></Dialog>
      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import DrugsItem from "@/components/modal/DrugsItem";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

export default {
  name: "drugsItemListManage",
  components: {
    DrugsItem,
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "index",
          label: "",
          // variant: "secondary",
          class: "text-center",
        },
        {
          key: "code",
          label: "รหัส",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "nameTh",
          label: "รายการสินค้า",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "nameEn",
          label: "รายการสินค้า(Eng)",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "cateName",
          label: "ประเภท",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "price",
          label: "ราคา",
        },
        {
          key: "creationDtShow",
          label: "วันที่สร้าง",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "buttons",
          label: "",
          class: "text-center",
        },
      ],
      filter: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      getItemList: "moduleAppointment/getItemList",
      getItemCateBy: "moduleAppointment/getItemCateBy",
      isAccessMenu: "moduleUser/getIsAccessMenu",
    }),
    itemList() {
      const itemList = [];
      this.getItemList.forEach((element) => {
        element.cateName = this.getItemCateName(element);
        element.creationDtShow = moment(element.creationDt)
          .locale("th")
          // .add(543, "year")
          .format("DD MMM YY");
        itemList.push(element);
      });
      return itemList;
    },
    isCounterOrManager() {
      return this.isAccessMenu(["COUNTER", "MANAGER", "HEADCOUNTER"]);
    },
  },
  watch: {},
  methods: {
    ...mapActions({}),
    init() {},
    moment() {
      return moment();
    },
    openDrugsItemModal(txItem) {
      this.$refs.DrugsItemModal.show(txItem);
    },
    favToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateItemFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteItemFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    deleteDrugsItem(eventData) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบการรักษานี้ ?",
        "คุณต้องการลบการรักษานี้"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("moduleAppointment/deleteItemList", {
              event: eventData,
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
            .then((res) => {
              if (res.status == "200") {
                this.isLoading = false;
                this.showDialogToast(
                  "success",
                  `ลบรายการ "${eventData.nameTh}" สำเร็จ`
                );
              }
            })
            .catch(() => {
              this.isLoading = false;
              this.showDialogToast(
                "warning",
                `ลบรายการ "${eventData.nameTh}" ไม่สำเร็จ`
              );
            })
            .finally(() => {
              this.$bvModal.hide("ItemItemModal");
            });
        }
      });
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    getItemCateName(val) {
      if (this.getItemCateBy(val.cateId, "id")) {
        return this.getItemCateBy(val.cateId, "id").nameTh;
      } else {
        return "";
      }
    },
  },
  updated() {},
  destroyed() {
    this.$store.dispatch("moduleAppointment/fetchItemCateAndList", {
      clinicUrl: this.$route.params.clinicUrl,
      branchUrl: this.$route.params.branchUrl,
    });
  },
};
</script>

<style scoped>
</style>
