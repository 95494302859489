<template>
  <b-modal
    modal-class="component-modal-itemItem"
    id="ItemItemModal"
    ref="ItemItemModal"
    size="lg fluid"
    title="รายการยา และสินค้า"
    hide-footer
    no-close-on-backdrop
    @close="close"
    @hide="close"
    @hidden="setDefaultValue"
  >
    <b-form @submit.prevent="saveDrugsItem">
      <b-form-group label-cols="3" label="รหัส:" label-align="right">
        <b-form-input v-model="itemItem.code"></b-form-input>
      </b-form-group>
      <b-form-group label-cols="3" label="ชื่อรายการ(ไทย):" label-align="right">
        <b-form-input
          v-model="itemItem.nameTh"
          class="required"
          :class="{ 'is-invalid': $v.itemItem.nameTh.$error }"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group label-cols="3" label="ชื่อรายการ(Eng):" label-align="right">
        <b-form-input v-model="itemItem.nameEn"></b-form-input>
      </b-form-group>
      <b-form-group label-cols="3" label="ราคา" label-align="right">
        <b-form-input
          v-model="itemItem.price"
          class="required"
          number
          :class="{ 'is-invalid': $v.itemItem.price.$error }"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group label-cols="3" label="ชื่อหน่วย" label-align="right">
        <b-form-input
          v-model="itemItem.unitName"
          class="required"
          :class="{ 'is-invalid': $v.itemItem.unitName.$error }"
        >
        </b-form-input>
      </b-form-group>

      <b-form-row>
        <div class="col-3 text-right align-self-center">ประเภท:</div>
        <b-form-select
          v-model="itemItem.cateId"
          class="col mx-1 required"
          :options="getItemCateList"
          value-field="id"
          text-field="nameTh"
          :class="{ 'is-invalid': $v.itemItem.cateId.$error }"
        >
          <template v-slot:first>
            <b-form-select-option :value="null" disabled
              >-- เลือกประเภท --</b-form-select-option
            >
          </template>
        </b-form-select>
        <b-button
          class="col-1 mx-1"
          block
          variant="secondary"
          @click="openDrugsCateItem()"
        >
          <i class="fas fa-edit"></i>
        </b-button>

        <!-- <b-button
          class="col-2 mx-1"
          variant="outline-info"
          :pressed="itemItem.favorite"
          @click="itemItem.favorite = !itemItem.favorite"
        >
          <i class="far fa-star">ใช้ประจำ</i>
        </b-button>-->
      </b-form-row>

      <b-form-group
        class="mt-3"
        label-cols="3"
        label="ค่าคอมมิชชั่นสินค้า:"
        label-align="right"
      >
        <b-row>
          <b-input-group class="col">
            <b-form-input
              v-model="itemItem.df"
              number
              @keyup="validateDf(itemItem.df)"
              class="required"
              :class="{ 'is-invalid': $v.itemItem.df.$error }"
            ></b-form-input>
            <template v-slot:append>
              <b-input-group-text>{{
                itemItem.dfType == 0 ? "%" : "บาท"
              }}</b-input-group-text>
            </template>
          </b-input-group>
          <b-form-radio-group
            class="align-self-center"
            v-model="itemItem.dfType"
          >
            <b-form-radio :value="0">Percent</b-form-radio>
            <b-form-radio :value="1">Fixed</b-form-radio>
          </b-form-radio-group>
          <b-button
            v-b-toggle.dfDetail
            class="col-3 mr-3"
            variant="secondary"
            size="sm"
            >DF แพทย์แต่ละท่าน</b-button
          >
        </b-row>
      </b-form-group>
      <b-row>
        <b-col cols="3"></b-col>
        <b-col class="px-2">
          <b-collapse id="dfDetail" class="mr-2">
            <b-card>
              <div class="mb-3">
                กรุณาเลือกแพทย์ที่ต้องการให้ DF เฉพาะท่าน
                <br />ในกรณีที่ไม่ได้เลือก DF จะคิดเท่ากับ "DF รายการรักษา"
              </div>
              <b-row
                class="mb-2"
                v-for="(dfSpec, index) in itemItem.dfItem"
                :key="dfSpec.uid"
              >
                <b-col cols="6" class="pr-0">
                  <div class="text-right pt-1">
                    {{ getUserListBy(dfSpec.uid, "uid").fullName }} :
                  </div>
                </b-col>
                <b-col>
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <!-- เพิ่ม data dfActive อีกตัว?? -->
                      <b-form-checkbox
                        switch
                        v-model="dfSpec.dfActive"
                        @input="activateDfSpec(dfSpec)"
                      ></b-form-checkbox>
                    </b-input-group-prepend>
                    <b-form-input
                      number
                      v-model="dfSpec.df"
                      @keyup="validateDfItem(dfSpec, index)"
                      :disabled="!dfSpec.dfActive"
                      required
                    ></b-form-input>
                    <b-input-group-append>
                      <b-input-group-text>{{
                        itemItem.dfType == 0 ? "%" : "บาท"
                      }}</b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-card>
          </b-collapse>
        </b-col>
      </b-row>
      <hr />
      <b-row class="d-flex flex-row-reverse">
        <div>
          <b-button class="ml-2 mr-3" type="submit" variant="primary">{{
            itemItem.id ? "แก้ไขรายการ" : "สร้างรายการ"
          }}</b-button>
        </div>
        <div>
          <b-button
            variant="danger"
            v-if="itemItem.id"
            @click="deleteDrugsItem(itemItem)"
            >ลบรายการ</b-button
          >
        </div>
      </b-row>
    </b-form>
    <Dialog ref="Dialog"></Dialog>
    <CateManage
      ref="DrugsCateItem"
      title="ประเภทยาและสินค้า"
      :categoryList="getItemCateList"
      :createCategoryFn="createItemCate"
      :deleteCategoryFn="deleteItemCate"
      :updateCategoryFn="updateItemCate"
      :fetchCategoryFn="fetchItemCateList"
    />
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import CateManage from "@/components/modal/CateManage";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
import "@/theme/modal/AptItem.scss";
import {
  required,
  requiredIf,
  numeric,
  minLength,
  between,
  sameAs,
  not,
  helpers,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "ItemItemModal",
  components: {
    Dialog,
    Loading,
    CateManage,
  },
  data() {
    return {
      isLoading: false,
      itemItem: {
        id: null,
        code: "",
        nameTh: "",
        nameEn: "",
        cateId: null,
        price: 0,
        unitName: null,
        favorite: false,
        df: null,
        dfType: 0,
        dfItem: [],
      },
    };
  },
  validations: {
    itemItem: {
      nameTh: { required },
      cateId: { required },
      unitName: { required },
      price: { required },
      df: { required },
    },
  },
  computed: {
    ...mapGetters({
      getItemCateList: "moduleAppointment/getItemCateList",
      getUserList: "moduleUser/getUserList",
      getUserListBy: "moduleUser/getUserListBy",
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      createItemCate: "moduleAppointment/createItemCate",
      updateItemCate: "moduleAppointment/updateItemCate",
      deleteItemCate: "moduleAppointment/deleteItemCate",
      fetchItemCateList: "moduleAppointment/fetchItemCateList",
    }),
    init() {},
    show(itemItem) {
      this.$bvModal.show("ItemItemModal");
      if (itemItem) {
        this.isLoading = true;
        this.$store
          .dispatch("moduleAppointment/findItemListById", {
            event: itemItem,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == 200) {
              this.itemItem = Object.assign({}, res.data);
              this.$bvModal.show("ItemItemModal");
            }
            this.isLoading = false;
          })
          .finally(() => {
            this.setDfItem();
          });
      } else {
        this.setDfItem();
      }
    },
    saveDrugsItem() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;

      delete this.itemItem.del;
      this.itemItem.dfItem = this.itemItem.dfItem.filter((i) => i.dfActive);

      if (!this.itemItem.id) {
        delete this.itemItem.id;
        this.$store
          .dispatch("moduleAppointment/createItemList", {
            event: this.itemItem,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == "200") {
              this.$parent.showDialogToast(
                "success",
                `สร้างรายการ "${this.itemItem.nameTh}" สำเร็จ`
              );
            }
            this.isLoading = false;
          })
          .finally(() => {
            this.isLoading = false;
            this.$bvModal.hide("ItemItemModal");
            this.setDefaultValue();
          });
      } else {
        this.$store
          .dispatch("moduleAppointment/updateItemList", {
            event: this.itemItem,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            this.$parent.showDialogToast(
              "success",
              `แก้ไขรายการ "${this.itemItem.nameTh}" สำเร็จ`
            );
          })
          .finally(() => {
            this.isLoading = false;
            this.$bvModal.hide("ItemItemModal");
          });
      }
    },
    deleteDrugsItem(eventData) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบการรักษานี้ ?",
        "คุณต้องการลบการรักษานี้"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("moduleAppointment/deleteItemList", {
              event: eventData,
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
            .then((res) => {
              if (res.status == "200") {
                this.setDefaultValue();
                this.$parent.showDialogToast(
                  "success",
                  `ลบรายการ "${eventData.nameTh}" สำเร็จ`
                );
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$parent.showDialogToast(
                "warning",
                `ลบรายการ "${eventData.nameTh}" ไม่สำเร็จ`
              );
            })
            .finally(() => {
              this.$bvModal.hide("ItemItemModal");
            });
        }
      });
    },
    setDfItem() {
      this.getUserList.forEach((user) => {
        let dfItem = {
          id: null,
          uid: user.uid,
          df: null,
          dfActive: false, // เพิ่มให้สามารถเปิดปิดเฉพาะคนได้
        };
        let index = this.itemItem.dfItem.findIndex((dfSpec) => {
          return dfSpec.uid == user.uid;
        });
        if (index < 0) {
          this.itemItem.dfItem.push(dfItem);
        } else {
          this.$set(this.itemItem.dfItem[index], "dfActive", true); // ให้เปิดใช้ในกรณีที่มี df เฉพาะ
        }
      });
    },
    close() {
      this.$v.$reset();
      this.setDefaultValue();
    },
    setDefaultValue() {
      this.itemItem = Object.assign(
        {},
        {
          id: null,
          code: "",
          nameTh: "",
          nameEn: "",
          cateId: null,
          price: 0,
          unitName: null,
          favorite: false,
          df: null,
          dfType: 0,
          dfItem: [],
        }
      );
    },
    openDrugsCateItem() {
      this.$refs.DrugsCateItem.show();
    },
    validateDf(df) {
      if (this.itemItem.dfType == 0) {
        if (df > 100) {
          this.itemItem.df = 100;
        }
      } else {
        if (df > this.itemItem.price) {
          this.itemItem.df = this.itemItem.price;
        }
      }
    },
    validateDfItem(dfSpec, index) {
      if (this.itemItem.dfType == 0) {
        if (dfSpec.df > 100) {
          dfSpec.df = 100;
        }
      } else {
        if (dfSpec.df > this.itemItem.price) {
          dfSpec.df = this.itemItem.price;
        }
      }
    },
    activateDfSpec(dfSpec) {
      if (!dfSpec.dfActive) {
        dfSpec.df = null;
      }
    },
  },
};
</script>

<style>
.required {
  background-color: #ccecff6c;
}
</style>